.item {
  width: 80%;
}

.center {
  width: 25%;
  margin-left: 24.8%;
}

.centerText {
  margin-left: 42%;
}

#container {
  position: relative;
}

#component2 {
  position: absolute;
  left: 50px;
  top: 10px;
}

.timePicker {
  width: 80%;
  margin-left: 40%;
}
