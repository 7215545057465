/*.item{
    width: 30%;
    text-align: center;
    margin-top: 2%;
    margin-left: 33%;
}*/

.items {
  width: 30%;
  text-align: center;
  margin-top: 2%;
}

.label {
  color: white;
}

#loginwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imgCenter {
  margin-top: 2%;
}

.backgroundColor {
  background-color: #053549;
  width: 100%;
  height: 100%;
}

.imgBorder {
  border-radius: 30px;
}

.spacing {
  margin-top: 2%;
  width: 87.5%;
  margin-left: 6.5%;
}

.box {
  width: 30%;
  text-align: center;
  margin-top: 6%;
  margin-left: 33%;
}

#container {
  position: relative;
}

#img2 {
  position: absolute;
  left: 50px;
  top: 5px;
}
