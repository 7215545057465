.rectangleSave {
  display: block;
  width: 350px;
  background: white;
  border: 1px;
  border-style: solid;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.rubrik {
  margin-bottom: 10%;
  font-size: 300%;
  text-align: center;
}
.pen-logo {
  width: 7%;
  border: 1px;
  border-style: solid;
  margin-left: 15px;
}
.username {
  font-style: italic;
  margin-left: 150px;
}
.input {
  width: 200px;
  margin-left: 80px;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px;
}
.alignment {
  margin-left: 20px;
  text-align: left;
}

#container {
  position: relative;
}

#position1 {
  position: absolute;
  left: 200px;
  top: 150px;
  width: 800px;
}

.font-size {
  font-size: 9px;
}

.font-size2 {
  font-size: 20px;
}

#position2 {
  position: absolute;
  left: 1000px;
  top: 150px;
  width: 450px;
}

#text {
  position: absolute;
  left: 1000px;
  top: 100px;
}

.input-username {
  left: auto;
}

.button-position {
  left: 250px;
}
