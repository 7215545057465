.rectangleAddAccount {
  display: block;
  width: 240px;
  background: white;
  text-align: right;
  text-align-last: center;
  border: 1px;
  border-style: solid;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
}
select {
  width: 100%;
}

#position1 {
  position: absolute;
  left: 200px;
  top: 150px;
  width: 800px;
}

#position2 {
  position: absolute;
  left: 500px;
  top: 400px;
  width: 450px;
}

.formPosition {
  width: 30%;
  margin-left: 31%;
  margin-top: 1%;
}

.formPosition2 {
  width: 24%;
  margin-left: 34.4%;
  margin-top: 1%;
}

.buttonPosition {
  margin-left: 82%;
}

.buttonPosition2 {
  margin-left: 55%;
}

.textPosition {
  margin-left: 44%;
  margin-bottom: 3%;
}

.selectPosition {
  width: 30%;
  margin-left: 15%;
}
